import React, { useEffect, useState } from "react";
import "../App.css";
import Header from "../Components/Header";
import Notification from "../Components/Notification";
import { useMediaQuery } from "react-responsive";
import dataModel from "../Components/dataModel";
import Endpoints from "../utils/repository/Endpoints";
let existingDate = [];

//fetch("https://eon-socket-dev.compliance.monster/auth");

// For Dev Dashboard
// fetch(Endpoints.socket_auth_dev);

// For Prod Dashboard
fetch(Endpoints.socket_auth_prod);

// Define the Dashboard component
const Dashboard = (alarmOn) => {
  // Set up a WebSocket connection to the server
  //const socket = new WebSocket("wss://eon-socket-dev.compliance.monster");
  // For Dev Dashboard
  // const socket = new WebSocket(Endpoints.socket_connection_dev);

  // For Prod Dashboard
  const socket = new WebSocket(Endpoints.socket_connection_prod);

  // Define state variables for managing the notification data and the screen orientation
  const [socketData, setSocketData] = useState([]); // Notification data received from the server
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" }); // Whether the screen is in portrait mode or not

  // Handle WebSocket messages
  useEffect(() => {
    socket.onmessage = (event) => {
      // Check if the received data is a Blob object
      if (event.data instanceof Blob) {
        // Read the Blob as text using a FileReader
        const reader = new FileReader();
        reader.readAsText(event.data);
        reader.onload = () => {
          if (socketData.length > 0) {
            // Parse the received text as JSON and process it
            processMessage(JSON.parse(reader.result));
            console.log("data is in json format ", reader.result);
          }
        };
      } else {
        // If the received data is not a Blob, parse it directly as JSON
        processMessage(JSON.parse(event.data));
      }
    };
  }, [onmessage]);

  //Process the received message
  // const processMessage = (data) => {
  //   if (!existingDate.includes(data.time)) {
  //     existingDate.push(data.time);
  //     setSocketData((socketData) => [data, ...socketData]);
  //   }
  // };

  // Function to play the alarm sound
  const playAlarmSound = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(500, audioContext.currentTime); // Set the frequency of the alarm sound
    oscillator.connect(audioContext.destination);
    oscillator.start();
    setTimeout(() => oscillator.stop(), 1000); // Stop the sound after 1 second
  };


  const processMessage = (data) => {
    const isNew = isNotificationRecent(data.time);
  
    if (!existingDate.includes(data.time)) {
      existingDate.push(data.time);
      setSocketData((socketData) => [{ ...data, isNew }, ...socketData]);

     // Play the alarm sound when a new notification arrives
        if (isNew) {
          playAlarmSound();
        }
      // Schedule a function to update isNew to false after 1 minute
      setTimeout(() => {
        setSocketData((prevSocketData) => {
          // Update the isNew property to false after 1 minute
          const updatedSocketData = prevSocketData.map((item) => {
            if (item.time === data.time) {
              return { ...item, isNew: false };
            }
            return item;
          });
          return updatedSocketData;
        });
      }, 60000); // 10000 milliseconds = 10 seconds
    }
  };
  
  
  // Function to check if the notification is recent (within the last 5 minutes)
  const isNotificationRecent = (notificationTime) => {
    const currentTime = new Date();
    const notificationDate = new Date(notificationTime);
    const timeDifference = currentTime - notificationDate;
    const secondsDifference = timeDifference / 1000;
    return secondsDifference <= 10;
  };
  
  

  console.log("Socket Data", socketData);


  return (
    <div>
      {/* <Header handleToggle={toggleAlarm} /> */}
      <div className={isPortrait ? "container" : "container-fluid"}>
        <div className="row">
          {/* Render a Notification component for each item in the socketData array */}
          {socketData.map((value, index) => {
            // Ensure the uniqueId property is added
            //value.uniqueId = '12qb00zj';
            
            return (
              <Notification
                isNew={value.isNew}
                alarmOn={alarmOn}
                key={index}
                name={value.name}
                userMotion={value.UserMotion}
                email={value.email}
                alertType={value.alert_type}
                position={value.position}
                time={value.time}
                uniqueId={value.uniqueId}
                major={value.major}
                minor={value.minor}
                proximity={value.proximity}
                txPower={value.txPower}
                batteryLevel={value.batteryLevel}
                deviceType={value.deviceType}
                rssi={value.rssi}
                location={value.location}
                beaconId={value.uniqueId}
                locationMap={value.User_Location}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

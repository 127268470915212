import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ImageCarousel = ({ images, closeModal }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  function decodeBase64ToImage(base64String) {
    return `data:image/png;base64,${base64String}`;
  }



  return (
    <div
      style={{
        position: "fixed",
        top: "10%", // Pushes the modal 10% lower vertically
        left: "50%", // Keeps it horizontally centered
        transform: "translate(-50%, 0)", // Centers horizontally, no vertical centering
        width: "90%",
        height: "90%",
        backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        borderRadius: "8px", // Optional rounded corners
      }}
    >
      {/* Close Button */}
      <button
        onClick={closeModal}
        style={{
          position: "absolute",
          top: "15px", // Adjust distance from the top
          right: "15px", // Adjust distance from the right
          backgroundColor: "red",
          color: "white",
          border: "none",
          borderRadius: "50%", // Makes it perfectly round
          width: "40px", // Equal width
          height: "40px", // Equal height
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)", // Adds a subtle shadow
          zIndex: 1001,
        }}
      >
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </button>

      {/* Carousel */}
      <div style={{ width: "100%", height: "100%" }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={decodeBase64ToImage(image.file_base64)}
                alt={`Image ${index}`}
                style={{
                  maxHeight: "70vh", // Keeps image visible within viewport
                  maxWidth: "90vw", // Prevents overflowing horizontally
                  objectFit: "contain", // No cropping, maintain proportions
                  margin: "auto",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ImageCarousel;
